<template>
  <div>
    <!--begin::Header -->
    <div
      class="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
      :style="{ backgroundImage: `url(${backgroundImage})` }"
    >
      <div class="d-flex align-items-center mr-2">
												<!--begin::Symbol-->
												<div class="symbol bg-white-o-15 mr-3">
                          <img v-if="false" alt="Pic" :src="currentUserPersonalInfo.photo" />
													<span v-if="true" class="symbol-label text-success font-weight-bold font-size-h4">{{ currentUserPersonalInfo.name.charAt(0).toUpperCase() }}</span>
												</div>
												<!--end::Symbol-->
												<!--begin::Text-->
												<div class="text-white m-0 flex-grow-1 mr-3 font-size-h5">{{ currentUserPersonalInfo.name }}</div>
												<!--end::Text-->
											</div>
    </div>
    <!--end::Header -->

    <div class="navi-footer px-8 py-5">
												<button @click="onLogout" target="_blank" class="btn btn-light-primary font-weight-bold">Sign Out</button>
											</div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { LOGOUT } from "@/core/services/store/auth.module";
export default {
  name: "KTDropdownUser",
  data() {
    return {
     
    };
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/misc/bg-1.jpg";
    },
    ...mapGetters(["currentUserPersonalInfo"]),

    getFullName() {
      return (
        this.currentUserPersonalInfo.name +
        " " +
        this.currentUserPersonalInfo.surname
      );
    }
  },
  methods: {
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "login" }));
    },
  },
};
</script>
